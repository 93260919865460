<template>
    <ul class="sidebar-nav">
        <li class="sidebar-item" v-for="item in menuItems" :key="item.id">
            <router-link class="sidebar-link" :to="item.url" :class="authUser.status == 1 ? 'disabled' : ''">
                <div>
                    <img :src="item.icon">
                    <span class="align-middle ml-2">{{ item.text }}</span>
                </div>
            </router-link>
        </li>
        <li class="sidebar-item">
            <a href="#" class="sidebar-link" @click.prevent="logout">
                <div>
                    <img src="@/assets/imgs/sidebar-icons/log-out.svg">
                    <span class="align-middle ml-2">Log Out</span>
                </div>
            </a>
        </li>
    </ul>
</template>
<script>
import config from '@/config'
export default {
    data() {
        return {
            menuItems: [
                { text: 'Dashboard', icon: require('@/assets/imgs/sidebar-icons/dashboard.svg'), url: 'dashboard' },
                { text: 'Add Parcel', icon: require('@/assets/imgs/sidebar-icons/add-parcel.svg'), url: 'add-parcel' },
                { text: 'Entry Parcel List', icon: require('@/assets/imgs/sidebar-icons/entry-parcel.svg'), url: 'entry-parcel-list' },
                { text: 'Under Review Parcel', icon: require('@/assets/imgs/sidebar-icons/review-parcel.svg'), url: 'under-review-parcel-list' },
                { text: 'Total Parcel List', icon: require('@/assets/imgs/sidebar-icons/parecl-list.svg'), url: 'total-parcel-list' },
                { text: 'Pickup Request List', icon: require('@/assets/imgs/sidebar-icons/pickup-request.svg'), url: 'pickup-request-list' },
                { text: 'Price Changed List', icon: require('@/assets/imgs/sidebar-icons/payment-request.svg'), url: 'price-changed-list' },
                { text: 'Payment Request List', icon: require('@/assets/imgs/sidebar-icons/payment-request.svg'), url: 'payment-request-list' },
                { text: 'Payment Received List', icon: require('@/assets/imgs/sidebar-icons/payment-request.svg'), url: 'payment-received-list' },
                { text: 'Return List', icon: require('@/assets/imgs/sidebar-icons/return-request.svg'), url: 'return-list' },
                // { text: 'Parcel Statistics', icon: require('@/assets/imgs/sidebar-icons/parcel-statistics.svg'), url: 'parcel-statistics' },
                { text: 'Coverage Area', icon: require('@/assets/imgs/sidebar-icons/coverage-area.svg'), url: 'covarage-area' },
                { text: 'Track Parcel', icon: require('@/assets/imgs/sidebar-icons/track-percel.svg'), url: 'track-parcel' },
                { text: 'Pricing Calculator', icon: require('@/assets/imgs/sidebar-icons/price-calculator.svg'), url: 'pricing-calculator' },
                { text: 'Support / Complain', icon: require('@/assets/imgs/sidebar-icons/support.svg'), url: 'complain' },
                { text: 'Fraud Check', icon: require('@/assets/imgs/sidebar-icons/fraud-check.svg'), url: 'fraud-check' },
                { text: 'API', icon: require('@/assets/imgs/sidebar-icons/api.svg'), url: 'api' },
                // { text: 'Log Out', icon: require('@/assets/imgs/sidebar-icons/log-out.svg'), url: 'logout' },
            ],
        };
    },
    computed: {    
        authUser: function () {
            return this.$store.state.authUser
        }
    },
    methods:{
        logout(){
            this.$router.push('/');
            config.postData('/logout')
            .then(() => {
                localStorage.removeItem('merchantAccessToken')          
                this.$store.dispatch('setAuthUser', '')
                this.$store.dispatch('setAccountDetails', '')
                this.$toast.success({
                    title: 'Success',
                    message: 'Logout successfully',
                    color: '#218838'
                })
            })      
        }
    }
};
</script>